'use client'

import '@/app/globals.css'
import { useEffect } from 'react'
const SLACK_WEBHOOK_URL = process.env.NEXT_PUBLIC_SLACK_WEBHOOK_URL

export default function GlobalError({
  error,
}: {
  error: Error & { digest?: string }
}) {
  useEffect(() => {
    const notifyError = async () => {
      if (!SLACK_WEBHOOK_URL) {
        console.error('Slack webhook URL is not configured')
        return
      }

      try {
        const currentUrl = window.location.href

        await fetch(SLACK_WEBHOOK_URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            blocks: [
              {
                type: "section",
                text: {
                  type: "mrkdwn",
                  text: "🚨 *EC-Frontでエラーが発生しました*"
                }
              },
              {
                type: "section",
                text: {
                  type: "mrkdwn",
                  text: `*URL:* ${currentUrl}`
                }
              },
              {
                type: "section",
                text: {
                  type: "mrkdwn",
                  text: `*エラー内容:*\n\`\`\`${error.message}\`\`\``
                }
              }
            ]
          })
        })
      } catch (e) {
        console.error('Failed to send error notification:', e)
      }
    }

    console.error('EC-Front global error:', error)
    void notifyError()
  }, [error])

  return (
    <html lang="ja">
      <head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </head>
      <body>
        <div className="w-full bg-white min-h-screen">
          <div className="lg:max-w-screen-lg lg:mx-auto mx-8 pt-8 pb-8">
            <p>申し訳ございません。</p>
            <p>エラーが発生しました。</p>
            <div className="mt-8">
              <a href="/">
                <button className="main-color bg-blue-500 text-white py-2 px-4 rounded w-full text-center">
                  TOPページへ戻る
                </button>
              </a>
            </div>
          </div>
        </div>
      </body>
    </html>
  )
}
